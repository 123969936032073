<template>
  <div>
    <!--    <el-page-header @back="goBack" content="商品列表"></el-page-header>-->
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>商品修改</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-form :model="editgoodslistForm" :rules="editgoodslistFormRules"
               ref="editgoodslistFormRef" label-width="100px" class="demo-dynamic">
        <el-form-item label="商品名称：" prop="g_name">
          <el-input v-model="editgoodslistForm.g_name"></el-input>
        </el-form-item>
        <el-form-item label="商品价格：" prop="g_name">
          <el-input v-model="editgoodslistForm.g_price"></el-input>
        </el-form-item>
        <el-form-item label="会员价格：" prop="g_name">
          <el-input v-model="editgoodslistForm.vip_price"></el-input>
        </el-form-item>
        <el-form-item label="成本价：" prop="g_name">
          <el-input v-model="editgoodslistForm.cost"></el-input>
        </el-form-item>
        <el-form-item label="市场价：" prop="g_name">
          <el-input v-model="editgoodslistForm.ot_price"></el-input>
        </el-form-item>
        <el-form-item label="虚拟销量：" prop="g_name">
          <el-input v-model="editgoodslistForm.g_ficti"></el-input>
        </el-form-item>
        <el-form-item label='商品主图' prop="ga_image_see">
          <el-upload class="avatar-uploader" :headers="headersObj"
                     :action="action" :show-file-list="false"
                     :on-success="handleAvatarSuccess" >
            <div v-if="editgoodslistForm.g_image_see" class="upload-avatar">
              <img  :src="editgoodslistForm.g_image_see">
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div  v-if="editgoodslistForm.g_image_see" class="upload-delete" @click="edithandleRemove">
            <i class="el-icon-close"></i>
          </div>
        </el-form-item>
        <el-form-item label="是否包邮：" >
          <el-switch v-model="editgoodslistForm.is_postage" @change="asd(editgoodslistForm.is_postage)" ></el-switch>
        </el-form-item>
        <el-form-item label="邮费："  v-if="editgoodslistForm.is_postage">
          <el-input v-model="editgoodslistForm.postage"></el-input>
        </el-form-item>
        <el-form-item label="获得积分：" >
          <el-input v-model="editgoodslistForm.give_integral"></el-input>
        </el-form-item>
        <el-form-item label="商品排序：" >
          <el-input v-model="editgoodslistForm.g_sort"></el-input>
        </el-form-item>
<!--        <el-form-item label="商品分类：" prop="g_name">-->
<!--          <el-input v-model="editgoodslistForm.c_id"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="计价单位：" prop="g_name">-->
<!--          <el-input v-model="editgoodslistForm.gu_id"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="质量等级：" prop="g_name">-->
<!--          <el-input v-model="editgoodslistForm.ql_id"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="商品分类：">
          <!-- options:数据源        -->
          <el-cascader v-model="selectefKeys" :options="categorieslList" clearable
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>

        </el-form-item>
        <el-form-item label="计量单位：" prop="gu_id">
          <el-select v-model="editgoodslistForm.gu_id" placeholder="请选择" clearable filterable>
            <el-option
                v-for="item in goodsunitlList"
                :key="item.gu_id"
                :label="item.gu_name"
                :value="item.gu_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="质量等级：" prop="ql_id">
          <el-select v-model="editgoodslistForm.ql_id" placeholder="请选择" clearable filterable>
            <el-option
                v-for="item in qualityleveList"
                :key="item.ql_id"
                :label="item.ql_name"
                :value="item.ql_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品描述：" prop="g_name">
<!--          <el-input v-model="editgoodslistForm.g_description"></el-input>-->
          <quill-editor v-model="editgoodslistForm.g_description"
                        ref="myQuillEditor"
                        class="ql-editor-class"
                        :options="editorOption">
          </quill-editor>
          <el-upload
              style="display: none"
              class="quill-picture-uploader"
              :action="action"
              :before-upload="bfUpload"
              :on-success="uploadSuccess"
              :headers="headersObj">
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-upload
              class="upload-demo" ref="upload" :headers="headersObj"
              :action="action" :on-success="handSuccess" :file-list="imges"
              :on-preview="handlePreview" :limit="5" :on-exceed="handExceed"
              :on-remove="handleRemove" clearFiles multiple
              list-type="picture">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="float: right;margin-bottom: 30px;" class="addbutton" @click="editgoods">修改商品</el-button>


    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    const toolOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{'header': 1}, {'header': 2}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'direction': 'rtl'}],
      [{'size': ['small', false, 'large', 'huge']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      [{'color': []}, {'background': []}],
      [{'font': []}],
      [{'align': []}],
      ['clean'],
      ['link', 'image', 'video']
    ];
    return {
      urlList:[],
      //修改分类图片的默认值
      imges:[],
      loading:true,
      editgoodslistForm:{},
      //选中的父级分类的ID数组
      selectefKeys: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      qualityleveList:[],
      goodsunitlList:[],
      categorieslList:[],
      editgoodslistFormRules:{
        g_name:[
          {required: true, message: '请输入', trigger: 'blur'}
        ],
      },
      action:'http://sale.aixspace.net/index.php/adminapi/upload/goods',
      //上传图片
      headersObj:{
        Authorization:'Bearer ' + window.sessionStorage.getItem('token')
      },
      editorOption: {
        placeholder: "正文内容支持上传图片和视频",
        theme: "snow",
        modules: {
          toolbar: {
            container: toolOptions,
            handlers: {
              image: function (value) {    //替换原图片上传功能
                if (value) {
                  document.querySelector(".quill-picture-uploader input").click(); //核心
                } else {
                  this.quill.format("image", false);
                }
              },
            }
          },
        },
      },



    }
  },
  created() {
    this.getGoodsListd()
    this.getcustomerlevelList()
    this.getgoodsunitList()
    this.getqualityleveList()
  },
  methods: {
    //获取商品
    async getGoodsListd(){
      this.loading=true
      const {data: res} = await this.$http.get('goods/findGoods/'+this.$route.query.id)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给catelist
      this.editgoodslistForm = res.data
      this.selectefKeys=res.data.c_id
      this.imges=res.data.g_slider_image
      this.loading=false
    },
    asd(as){
      console.log(as)
    },
    //获取商品分类
    async getcustomerlevelList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }

      this.categorieslList = res.data
    },
    //获取计量列表
    async getgoodsunitList() {
      const {data: res} = await this.$http.get('goodsunit/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.goodsunitlList = res.data
    },
    //获取质量列表
    async getqualityleveList() {
      const {data: res} = await this.$http.get('qualityleve/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.qualityleveList = res.data
    },

    //监听图片上传成功
    handleAvatarSuccess(res) {
      this.editgoodslistForm.g_image_see=res.data.url
      this.editgoodslistForm.g_image=res.data.saveurl
    },
    edithandleRemove(){
      this.editgoodslistForm.g_image_see=''
      this.editgoodslistForm.g_image=''
    },


    //上传图片之前校验
    bfUpload(file) {
      console.log(file)
      if ("image/png" == file.type || "image/jpeg" == file.type) {
        console.log(1)
      } else {
        this.$message.error('图片插入失败,请检查文件格式');
        return;
      }
    },
    //选择项发生变化触发这个函数
    parentCateChanged() {
      this.editgoodslistForm.c_id = this.selectefKeys[this.selectefKeys.length - 1]

    },
    //正文插入图片上传成功调用
    uploadSuccess(response, file, fileList) {
      console.log(response,123)
      this.urlList.push(response.url);
      let quill = this.$refs.myQuillEditor.quill;
      if (response.data.url != null) {
        console.log(response,456)
        //获取光标所在位置
        let length = quill.getSelection().index;
        //插入图片
        quill.insertEmbed(length, 'image', response.data.url);
        //移动光标到图片后
        quill.setSelection(length + 1);
      }
    },
    async editgoods(){
      console.log(this.editgoodslistForm)
      const {data: res} = await this.$http.post(`goods/editGoods`, this.editgoodslistForm)
      if (res.code != 200) {
        return this.$message.error('更新状态失败')
      }
      this.$message.success('更新状态成功')
    },
    //轮播
    //监听图片上传成功的事件
    handSuccess(e, file, fileList) {
      if(fileList){
        this.fileList = fileList.map(res => {
          if(res.raw) {
            return {
              name: res.response.data.name,
              status: res.status,
              url: res.response.data.url,
              url_save:res.response.data.saveurl
            }
          }else {
            return {
              name: res.name,
              status: res.status,
              url: res.url,
              url_save: res.url_save
            }
          }
        })
      }

      console.log(this.fileList)
      this.editgoodslistForm.g_slider_image=this.fileList
    },
    //处理图片预览效果
    handlePreview(file) {
      console.log(file);
    },
    //文件超出个数限制时的事件
    handExceed(files, fileList){
      console.log(files, fileList)
      this.$message.error('上传图片超出个数限制，请删除后重新上传')
    },
    //处理移除图片的操作
    handleRemove(file, fileList) {
      this.editgoodslistForm.g_slider_image.forEach((item,index)=>{
        if (file.name==item.name){
          this.editgoodslistForm.g_slider_image.splice(index,1)
        }

      })
    },

  }
}
</script>

<style lang="less" scoped>
.el-col{
  margin-bottom: 20px;
}
</style>
